import React, { Component } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { colors } from "Utilities"
import { Card } from "../assets/Cards"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GoogleApiWrapper from "../components/Map/GoogleMap"
import StyledContactPageBanner from "../components/Background-Images/contactBackground"

gsap.registerPlugin(ScrollTrigger)

const infoText = {
  textDecoration: "none",
  color: "#222",
  cursor: "pointer",
}

const divider = {
  display: "inline-block",
  width: "250px",
  height: "2px",
  background: "linear-gradient(to right,#000000 0,#000000 50%)",
  marginTop: "20px",
  marginBottom: "20px",
}

class Contact extends Component {
  componentDidMount() {
    gsap.from(".contactImage", {
      scrollTrigger: {
        trigger: ".contactImage",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".border", {
      scrollTrigger: {
        trigger: ".border",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
    gsap.from(".contactMap", {
      scrollTrigger: {
        trigger: ".contactMap",
        start: "top 85%",
        toggleActions: "play none none none",
      },
      y: 300,
      opacity: 0,
      ease: "power1.inOut",
      duration: 1,
    })
  }
  render() {
    return (
      <Layout>
        <SEO title="Contact" />
        <Container>
          <StyledContactPageBanner
            ref={div => (this.myElement = div)}
            className="contactImage"
          >
            <TextContainer>
              <ParkWrapper>
                <h1>Contact Us.</h1>
                <span style={divider}></span>
                <br />
                <ContactWrapper>
                  <StormWrapper>
                    <p>ADDRESS</p>
                    <p>
                      Ashcott Lawns,
                      <br />4 The Batch,
                      <br /> Ashcott,
                      <br /> Bridgwater,
                      <br />
                      TA7 9PH
                    </p>
                    <span style={divider}></span>
                  </StormWrapper>
                  <PhoneWrapper>
                    <p>TELEPHONE:</p>
                    <a style={infoText} href="tel:01458211172">
                      <p>+44 &#40;0&#41;1458 211172</p>
                    </a>

                    <span style={divider}></span>
                    <br />
                    <p>EMAIL:</p>
                    <a style={infoText} href="mailto:info@ashcottlawns.co.uk">
                      <p>info@ashcottlawns.co.uk</p>
                    </a>
                  </PhoneWrapper>
                </ContactWrapper>
              </ParkWrapper>
              <ParkWrapper>
                <h1>Road Directions.</h1>
                <span style={divider}></span>
                <br />
                <p>
                  Ashcott Lawns is located in the southern part of the village.
                  It is easily accessible from the M5, taking junction 23 onto
                  A39, signposted towards Wells. After approx. 8 miles, you will
                  enter Ashcott. Turn left on to The Batch and after approx. 100
                  yards, turn right following the sign into Ashcott Lawns.{" "}
                  <br /> Alternatively, head into Ashcott on the A39 from
                  Street, turn right onto The Batch and after approx. 100 yards,
                  turn right following the sign into Ashcott Lawns.
                </p>
              </ParkWrapper>
            </TextContainer>
          </StyledContactPageBanner>
          <Border ref={div => (this.myElement = div)} className="border" />
          <GoogleWrapper
            ref={div => (this.myElement = div)}
            className="contactMap"
          >
            <GoogleApiWrapper />
          </GoogleWrapper>
        </Container>
      </Layout>
    )
  }
}
export default Contact

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding: 1rem 0;
  @media (max-width: 769px) {
    padding: 1rem 0rem;
  }
`

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 3rem;
  margin: 3rem 3rem;
  @media (max-width: 769px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin: 3rem 1rem;
  }
`

const ParkWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  height: 100%;
  padding: 2rem;
  background-color: ${colors.lightGrey};
  h1 {
    color: ${colors.black};
    font-weight: lighter;
  }
`
const ContactWrapper = styled.div`
  display: flex;

  justify-content: space-around;
  width: 100%;
  @media (max-width: 769px) {
    flex-wrap: wrap;
  }
`
const StormWrapper = styled.div`
  padding: 0 1rem;
  width: 100%;
  min-width: 100px;
  p {
    min-width: 200px;
    margin: 0;
  }
`

const PhoneWrapper = styled.div`
  padding: 0 1rem;
  width: 100%;
  p {
    margin: 0;
  }
`

const Border = styled.div`
  width: 100%;
  background: #1a2e05;
  border-radius: 5px;
  height: 3em;
  margin-top: 1rem;
  box-shadow: 0px 5px 0px 0px #eab308;
`

const GoogleWrapper = styled.div`
  margin: 1rem 0;
  @media (max-width: 769px) {
    margin: 1rem 1rem;
  }
`
