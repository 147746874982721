import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '400px'
}

const style = {
  width: '100%',
  height: '100%',
};

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,  
    activeMarker: {},          
    selectedPlace: {}         
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

    onClose = props => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        });
      }
    };

  render() {
    return (
      
      <Map
        containerStyle={containerStyle}
        google={this.props.google}
        zoom={15}
        initialCenter ={{ lat:51.129170 , lng:-2.808861 }}
        style={style}
      >
        <Marker 
          onClick={this.onMarkerClick}
          name={'Ashcott Lawns'}
        />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </Map> 
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB3Wc8l13yxlLzkzvE9oHCj5TSx2sko5tg'
})(MapContainer);